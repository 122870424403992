import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    const [email, setEmail] = useState('')
    const [done, setDone] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        if (done) {
            fetch('https://api.dev.boonandbliss.com/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    email,
                    'referral-code': 'a-code'
                },
                body: JSON.stringify({
                    email,
                    referralCode: 'a-code-1'
                })
            }).catch((e) => setError(`${e}`))
        }
    }, [done])
    const handleSubmit = (e: FormEvent) => {
        console.log('ura', e)
        setDone(true)
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event?.target?.value || '')
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                {error ? <pre>{error}</pre> : null}
                {done
                    ? 'Thank you'
                    : <form onSubmit={handleSubmit} method="post">
                        <div>
                            <h1>Anton invited you! Hoera!</h1>
                            Enter your email:
                            <input type="email" name="name" value={email} onChange={handleChange}/>
                            <input type="submit" value="Submit"/>
                        </div>
                    </form>
                }
            </header>
        </div>
    );
}

export default App;
